@media (max-width:480px), (max-width:991px){
    .imagecarded {
        width: 50%;
    }
}

@media (max-width:770px), (max-width:900px){
    .imagecarded {
        width: 40%;
    }
}