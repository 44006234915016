.gallery {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    -webkit-column-width: 25%;
    -moz-column-width: 25%;
    column-width: 25%;
    padding: 5px 12px;
}

.pics {
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
}

.pics:hover {
    filter: opacity(.8);
}

@media (max-width:991px) {
    .gallery {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }
}

@media (max-width:480px) {
    .gallery {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
        column-width: 100%;
    }
}

.model {
    width: 100%;
    height: 80vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
}

.model.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.model img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}

.model.open svg {
    position: absolute;
    top: 150px;
    right: 10px;
    width: 2rem;
    height: 2rem;
    padding: 5px;
    background-color: rgb(0, 0, 0);
    color: white;
    cursor: pointer;
}

.closeicon {
    position: relative;
    bottom: 120px;
    left: 0;
}

.nexticon {
    position: relative;
    bottom: 240px;
    right: 0;
}

.previcon {
    position: absolute;
    top: 80px;
    right: 0;
}

.model .caption {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 18px;
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
}