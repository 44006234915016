.imageC {
  height: 65vh;
  width: 100%;
  border-radius: 8px;
}

.carouseltext {
  text-align: left;
  color: whitesmoke;
}

.carouseltext1 {
  text-align: right;
  color: whitesmoke;
}

.carous {
  justify-content: center;
  padding-top: 1px;
}


@media (max-width: 600px) {
  .imageC {
    height: 40vh;
    border-radius: 4px;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .imageC {
    height: 65vh;
    border-radius: 6px;
  }
}