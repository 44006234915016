body {
  margin-top: 70px !important;
}

@media (max-width: 991px) {
  body {
    margin-top: 140px;
  }
}

@media (max-width: 767px) {
  body {
    margin-top: 100px;
  }
}

@media (max-width: 575px) {
  body {
    margin-top: 60px;
  }
}

@media (max-width: 479px) {
  body {
    margin-top: 20px;
  }
}

.section {
  padding-top: 25px;
  padding-bottom: 25px;
}

.section1 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.main-heading {
  color: #F98F22;
  font-size: 18px;
  font-weight: 500;
  text-shadow: black;
}

.main-heading-vmc {
  color: whitesmoke;
  font-size: 18px;
  font-weight: 500;
  text-shadow: black;
  background-color: black;
}

.underline {
  height: 0.4px;
  width: 50rem;
  background-color: #F98F22;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width:991px) {
  .underline {
    height: 0.4px;
    width: 18rem;
    background-color: #F98F22;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width:480px) {
  .underline {
    height: 0.4px;
    width: 14rem;
    background-color: #F98F22;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width:320px) {
  .underline {
    height: 0.4px;
    width: 10rem;
    background-color: #F98F22;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width:991px) {
  .underline-card {
    height: 0.4px;
    width: 8rem;
    background-color: #F98F22;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width:480px) {
  .underline-card {
    height: 0.4px;
    width: 8rem;
    background-color: #F98F22;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width:320px) {
  .underline-card {
    height: 0.4px;
    width: 8rem;
    background-color: #F98F22;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width:991px) {
  .underline-card1 {
    height: 0.4px;
    width: 18rem;
    background-color: #F98F22;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width:480px) {
  .underline-card1 {
    height: 0.4px;
    width: 14rem;
    background-color: #F98F22;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width:320px) {
  .underline-card1 {
    height: 0.4px;
    width: 10rem;
    background-color: #F98F22;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.underline-card {
  height: 0.4px;
  width: 11rem;
  background-color: #F98F22;
  margin-top: 10px;
  margin-bottom: 10px;
}

.underline-card1 {
  height: 0.4px;
  width: 30rem;
  background-color: #F98F22;
  margin-top: 10px;
  margin-bottom: 10px;
}

.underline-card2 {
  height: 0.4px;
  width: 25rem;
  background-color: #F98F22;
  margin-top: 10px;
  margin-bottom: 10px;
}

.underline-card3 {
  height: 0.4px;
  width: 12rem;
  background-color: #F98F22;
  margin-top: 10px;
  margin-bottom: 10px;
}

.underline-card4 {
  height: 0.4px;
  width: 17rem;
  background-color: #F98F22;
  margin-top: 10px;
  margin-bottom: 10px;
}

.bg-c-light {
  background-color: #F98F22;
}

.footer {
  padding-top: 30px;
}

.footer a {
  color: #fff;
  text-decoration: none !important;
  text-align: center;
}

.footera{
  font-size: 13.2px;
}

.fotr {
  line-height: 2;
  font-size: 13.2px;
}

.footer a:hover {
  color: #F98F22;
}

.footer p {
  color: #fff;
  text-decoration: none !important;
  text-align: justify;
}

.section-footer {
  background-color: #F98F22;
  color: black !important;
}

.section-footer:hover {
  color: whitesmoke !important;
  background-color: rgb(56, 54, 54);
}

.covered {
  background-color: gray;
}

.covid {
  color: red;
}

.texty {
  text-align: justify;
  text-shadow: 0px 0px 0px black;
  font-size: 14px;
}

.para {
  text-align: center;
  padding-top: 10px;
}

.butts {
  justify-content: center;
}

.bold {
  color: black;
}

.vmcs {
  font-weight: 500;
  color: black;
  text-shadow: black;
}

.vmct {
  color: whitesmoke;
  text-shadow: whitesmoke;
  font-weight: 500;
}

.bttn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.objs {
  background-color: black;
  margin-bottom: 10pt;
  padding-bottom: 20px;
}

.textfoot{
  font-size: small;
}