.navba {
    background-color: #fff8f0;
    color: #F98F22;
}

.home {
    color: black !important;
    font-size: 14.5px;
    font-weight: 500;
    text-shadow: 0px 0px 0px black;
}

.home:hover {
    text-decoration: underline;
    color: #F98F22 !important;
    text-shadow: 0px 0px #F98F22;
    font-size: 14.5px !important;
    font-weight: 500 !important;
    border-radius: 10px;
    pointer-events: fill;
}

.logo {
    border-radius: 50px;
}

.logot {
    color: #F98F22;
    text-shadow: 1px 1px black;
    font-size: 22px;
    margin: 0px;
}

.logot:hover {
    color: hsl(0, 0%, 0%);
    text-shadow: 0px 0px #F98F22;
}

.snall {
    font-size: x-small;
    text-shadow: 0px 0px black;
    text-align: center;
}

.logos {
    justify-content: left;
}

@media (max-width: 991px) {
    .snall {
        font-size: x-small;
        text-shadow: 0px 0px black;
        text-align: justify;
    }
}

@media (max-width: 991px) {
    .logot {
        color: #F98F22;
        text-shadow: 1px 1px black;
        font-size: 14px;
        margin: 0px;
    }
}