.form-group {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.header {
    text-align: center;
    padding-left: 5px;
    color: #F98F22;
    font-size: 20px;
}

.media {
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
}

.media1 {
    padding-left: 5px;
    padding-right: 5px;
}

.bold {
    font-weight: bold;
}

.para {
    color: black !important;
}

.para:hover {
    color: black !important;
}

.mapped {
    height: 420px;
}

@media (max-width:991px) {
    .mapped {
        height: 420px;
        width: 100%;
    }
}

@media (max-width:480px) {
    .mapped {
        height: 360px;
        width: 100%;
    }
}

@media (min-width: 601px) and (max-width: 900px) {
    .mapped {
        height: 420px;
        width: 100%;
    }
}

.head-text {
    position: relative;
}

.text-on-image {
    position: absolute;
    left: 7%;
    color: black;
    bottom: 15%;
    font-size: 20px;
}

.text-center {
    text-align: center;
}

.text1 {
    color: whitesmoke;
}

.imagecard {
    position: relative;
    margin-left: 0px;
}

.iconb {
    padding-bottom: 10px;
}

.texticon {
    text-align: center;
    text-shadow: 0px 0px 0px black;
    font-size: 14px;
}

.red{
    color: red;
}