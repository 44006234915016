.cardp {
    font-size: 13px;
    text-align: justify;
    font-weight: 500;
}

.cardh {
    font-size: 20px;
    color: tomato;
    text-underline-position: below !important;
}

.texthome {
    text-align: justify;
    text-shadow: 0px 0px 0px black;
    font-size: 14px;
}

.texthead {
    text-align: left;
    text-shadow: 0px 0px 0px black;
}