.title  
{
        margin-bottom:20px;  
        padding:20px  
}

.img  
{  
        height:260px;
        width:100%  
}

.text1{
        color: black;
        text-align: justify;
}

.cardowl{
        background-color: rgb(208, 150, 255);
        color: black
}

.cardow2{
        background-color: rgb(106, 238, 250);
}

.cardow3{
        background-color: rgb(156, 240, 177);
}

.cardow4{
        background-color: rgb(154, 161, 255);
}

.owls{
        align-items: center;
        align-content: center;
        justify-items: center;
        justify-content: center;
        justify-self: center;
}