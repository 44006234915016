.main-heading-obj {
  color: whitesmoke;
  font-size: 18px;
  font-weight: 500;
  text-shadow: whitesmoke;
  background-color: white;
  color: #F98F22;
}

.objectiv {
  background-color: #fff8f0;
}

.texthome {
  text-align: justify;
  text-shadow: 0px 0px 0px black;
}

.texthome1 {
  text-align: justify;
  font-size: 14px;
  text-shadow: 0px 0px 0px black;
}

.main-heading-para {
  text-shadow: whitesmoke;
  background-color: white;
}

.extend {
  padding-left: 10px;
}