.cardcont {
    padding-top: 20px;
    margin-left: 20px;
    align-content: flex-end;
}

.cardcontext {
    padding-top: 20px;
    margin-left: 20px;
    align-content: center;
}

.shadowpara {
    margin-left: 20px;
    padding-top: 8px;
    padding-bottom: 20px;
    padding-right: 20px;
    text-align: center;
    text-shadow: 0px 0px 0px black;
    font-size: 14.5px;
}

.mainheading {
    color: #008250;
    font-size: 20px;
    font-weight: 500;
    text-shadow: black;
}

.borders {
    background-color: #fff8f0;
}

.borders1 {
    background-color: whitesmoke;
}

.underlinecard {
    height: 0.4px;
    width: 8rem;
    background-color: #008250;
    margin-top: 10px;
    margin-bottom: 10px;
}