.head-text {
    position: relative;
}

.text-on-image {
    position: absolute;
    left: 7%;
    color: black;
    bottom: 15%;
    font-size: 20px;
}

.cardimg {
    position: relative;
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
}

.cardimg.img {
    justify-content: center;
    align-items: center;
    align-content: center;
}

.bold1 {
    text-align: center;
    color: black;
    font-weight: 650;
    font-size: 18px;
}

.bold2 {
    text-align: center;
    color: black;
    font-weight: 650;
    font-size: 14.5px;
}

.boldcard {
    font-size: 14px;
    text-align: center;
    color: black;
    text-shadow: 0px 0px 0px black;
}

.bold3 {
    text-align: justify;
    color: black;
    text-shadow: 0px 0px 0px black;
    font-size: 14px;
}

.bold4 {
    text-align: center;
    color: black;
    font-size: 14px;
    text-shadow: 0px 0px 0px black;
}

.bold5 {
    text-align: center;
    color: black;
    font-size: 13px;
}

.bold6 {
    color: black;
}

.bold1:hover {
    color: #F98F22;
}

.bold2:hover {
    color: #F98F22;
}

.bold6:hover {
    color: #F98F22;
}

.text-center {
    align-items: center;
}

.text1 {
    color: black !important;
    font-size: 15px;
}

.imagecard {
    position: relative;
    margin-left: 0px;
}

.textBOD {
    color: black;
    text-align: justify;
    font-size: 14px;
    text-shadow: 0px 0px 0px black;
}

.textcenter1 {
    padding-bottom: 10px;
    display: flex;
}

.textcenter2 {
    align-items: center;
    align-content: center;
    display: flex;
}

@media (max-width:991px) {
    .textcenter1 {
        align-items: flex;
        align-content: flex;
    }
}

@media (max-width:480px) {
    .textcenter1 {
        align-items: flex;
        align-content: flex;
    }
}

@media (max-width:320px) {
    .textcenter1 {
        align-items: flex;
        align-content: flex;
    }
}

.texthome1 {
    text-align: justify;
    text-shadow: 0px 0px 0px black;
    font-size: 14px;
}

.signoff {
    text-align: center;
    font-size: 14px;
    color: #F98F22;
    font-weight: bold;
}

.signoff:hover {
    color: black;
}

.textsignoff {
    color: black;
    text-align: center;
    font-size: 14px;
    text-shadow: 0px 0px 0px black;
}

.twitter-timeline {
    height: 10px;
    width: 10px;
}